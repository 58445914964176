





























import {Component, Vue} from "vue-property-decorator";
import NorthStarService from "@/NorthStarService";
import {NorthStar} from "@/typescript/types";
import Template from "@/components/workspace/Template.vue";
import NorthStarDetailed from "@/components/northstars/NorthStarDetailed.vue";
import NorthStarData from "@/components/northstars/NorthStarData.vue";

@Component({
  components: {NorthStarData, Template}
})
export default class BoardGoal extends Vue {

  service: NorthStarService | null = null;
  goalId: string | null = null;
  northStar: NorthStar | null = null;


  openNorthstarDetailed() {
    this.$buefy.modal.open({
      component: NorthStarDetailed,
      props: {
        board: this.$store.state.board
      },
      width: '860px',
      // @ts-ignore
      customClass: 'northstar-detailed',
      parent: this,
    });
  }
}
