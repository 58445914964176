








import {Component, Prop, Vue} from "vue-property-decorator";
import NorthStarData from "@/components/northstars/NorthStarData.vue";
import {Board as BoardObject} from "@/typescript/types";
@Component({
  components: {NorthStarData}
})
export default class NorthStarDetailed extends Vue {

  @Prop({default: undefined, required: false})
  board!: BoardObject;

  @Prop({default: undefined, required: true})
  id!: String;

  closeModal() {
    // @ts-ignore
    
  }
}
